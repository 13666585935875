<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
        action-label="แก้ไขข้อมูล"
      >
      <template #cell(status)="{ item }">
          <!-- <pre>{{ item }}</pre> -->
          <div class="text-center" v-if="item.activeFlag">
            อนุมัติแล้ว
          </div>
          <div class="text-center" v-if="!item.activeFlag">
            รอการอนุมัติ
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <!-- <pre>{{ item }}</pre> -->
          <div class="text-center">
            <b-button size="sm" @click="edit(item)">{{
              actionButtonText
            }}</b-button>
          </div>
        </template>

        <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button variant="danger" size="sm" @click="remove(item)"
              >ลบ</b-button
            >
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
// import { calculateRetireYear } from "../../helpers/datepicker-helper";
import formatDateMixin from "../../mixins/formatDateMixin";
// import { MRank, User } from "../../models";
import BaseTable from "./Base";
import axios from "axios";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "แก้ไข",
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      sortBy: "",
      sortDesc: false,
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch() {
      let data = [];
        // additionalParams = {},
        let numberOfPages = 1;

      this.isFetching = true;

     

      try {
         var promise = await axios.get(
        `https://e-learnning-service-temp.yuzudigital.com/users`
      );
      console.log(promise.data);
        // const promise = await Survey.api().findAll(
        //   {
        //     ...additionalParams,
        //     limit: ctx.perPage,
        //     offset: (ctx.currentPage - 1) * ctx.perPage,
        //   },
        //   { save: false }
        // );

        let {
          data: responseData = [],
          number_of_pages = 1,
        } = promise.data;

        data = responseData.map((record) => {
          return {
            ...record,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลแบบประเมินได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      this.numberOfPages = numberOfPages;

      return data;
    },

    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
